<template>
    <!--sidebar-menu :menu="menu" /-->
    <aside class="menu-area" >
        <nav class="menu" >
            <router-link to="/">
                <i class="fa fa-home" title="Home"></i>
                <strong v-show="isMenuVisible"> Home</strong>
            </router-link>
              <router-link v-if="user.admin === 0" to="/company">
                <i class="fa fa-building" title="Dados da Empresa"></i>
                <strong v-show="isMenuVisible"> Dados da Empresa</strong>
            </router-link>
            <router-link v-if="user.admin === 0" to="/employee">
                <i class="fa fa-newspaper-o" title="Cadastro do Funcionário"></i>
                <strong v-show="isMenuVisible"> Cadastro do Funcionário</strong>
            </router-link>
            <router-link v-if="user.admin === 0" to="/companydocumentmonthly">
                <i class="fa fa-calendar" title="Documentação Mensal"></i>
                <strong v-show="isMenuVisible"> Documentação Mensal</strong>
            </router-link>
            <router-link v-if="user.admin === 0" to="/companyvalidatedates">
                <i class="fa fa-list-alt" title="Documentação (Data de Validade)"></i>
                <strong v-show="isMenuVisible"> Documentação (Data de Validade) - ({{this.users_file_validate_dates.length}})</strong>
                <h6 v-show="!isMenuVisible"> <b-badge pill title="Documento(s) Rejeitado(s)"  variant="light">{{this.users_file_validate_dates.length}}</b-badge> </h6>
            </router-link>
             <b-button v-b-toggle.sidebar-1 v-if="user.admin === 0" >
                <i class="fa fa-exclamation" title="Documentos(s) Rejeitado(s)"></i>
                <strong v-show="isMenuVisible"> Documento Rejeitado ({{this.company_employee_files.length + this.users_files.length + this.users_files_compl.length + this.company_monthly_files.length}}) </strong>
                <h6 v-show="!isMenuVisible"> <b-badge pill title="Documento(s) Rejeitado(s)"  variant="light">{{this.company_employee_files.length + this.users_files.length + this.users_files_compl.length + this.company_monthly_files.length}}</b-badge> </h6>
            </b-button>
            <router-link v-if="user.admin === 1" to="/admin">
                <i class="fa fa-cogs" title="Administração"></i
                ><strong v-show="isMenuVisible"> Administração</strong>
            </router-link>
        </nav>
            <b-sidebar id="sidebar-1" title=""  shadow>
                <!--router-link to="/rejectfileemployee">
                    <i  v-show="isMenuVisible" class="fa fa-exclamation" ></i>
                    <strong v-show="isMenuVisible"> Documento(s) Rejeitado(s) ({{this.company_employee_files.length + this.users_files.length + this.users_files_compl.length + this.company_monthly_files.length}}) </strong>              
                    <h6 > <b-badge pill title="Documento(s) Rejeitado(s)"  variant="light">{{this.company_employee_files.length + this.users_files.length + this.users_files_compl.length + this.company_monthly_files.length}}</b-badge> </h6>
                </router-link!-->
                 <router-link to="/rejectfilecompany">
                  
                    <strong > Empresa ({{this.users_files.length}}) </strong>              
                  
                </router-link>
                 <router-link to="/rejectfilecompanyemployee">
                   
                    <strong > Funcionário ({{this.company_employee_files.length}}) </strong>              
                  
                </router-link>
                <router-link to="/rejectfilemonth">
                    
                    <strong > Mensal ({{this.company_monthly_files.length}}) </strong>              
                    
                </router-link>
               
            </b-sidebar>
    </aside>
</template>

<script>
import { mapState } from 'vuex'
import { baseApiUrl } from '@/global'
import axios from 'axios'

export default {
    name: 'Menu',
     data: function() {
        return {
            company_employee_files:[],
            users_files: [],
            users_file_validate_dates: [],
            users_files_compl: [],
            company_monthly_files:[],
        }
    },
    computed: mapState({
        isMenuVisible: 'isMenuVisible',
        menuDisabled: 'isMenuVisible',
        user() {
            return this.$store.state.user
        }
    }),
    methods: {
       async    loadCompanyEmployeeFiles() {
            const url = `${baseApiUrl}/companyemployeefilesuserscompanys/${this.user.id}`
        await    axios(url).then(res => {
                this.company_employee_files = res.data
            })
        },
        async loadUserFiles() {
              const url = `${baseApiUrl}/usersfilerejectuser/${this.user.id}`
             await   axios(url).then(res => {
                this.users_files = res.data
            })
        },
        async loadUserFilesValidateDates() {
              const url = `${baseApiUrl}/usersfilerejectuservalidatedates/${this.user.id}`
             await   axios(url).then(res => {
                this.users_file_validate_dates = res.data
            })
        },
         async loadUserFilesCompl() {
              const url = `${baseApiUrl}/usersfilerejectusercompl/${this.user.id}`
             await   axios(url).then(res => {
                this.users_files_compl = res.data
            })
        },
        async loadMonthlyFiles() {

            const url = `${baseApiUrl}/companydocumentmonthlyfilereject/${this.user.id}`
            await axios.get(url).then(res => {
                this.company_monthly_files = res.data

            })

        },    
    },
     async  mounted() {
      await  this.loadCompanyEmployeeFiles()
      await this.loadUserFiles()
      await this.loadUserFilesCompl()
      await this.loadMonthlyFiles()
      await this.loadUserFilesValidateDates()
    },

   async created() {
         setInterval(async () => Promise.all([
                                        await  this.loadCompanyEmployeeFiles(),
                                        await this.loadUserFiles(),
                                        await this.loadUserFilesCompl(),
                                        await this.loadMonthlyFiles(),
                                        await this.loadUserFilesValidateDates()
                                      ]
                                      )  , 1 * 30000);
    }
}
</script>

<style>
.aside.menu-area {
    background-color: #0fdfdf;
    box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.12),
        2px 0 15px 0 rgba(0, 0, 0, 0.09);
}
.menu a {
    display: block;
    text-decoration: none;
    color: #fff;
    font-weight: 300;
    padding: 5px;
    font-size: 0.75rem;
    background: #03001e;
}
.menu h6{
    font-size: 0.75rem;
}

.menu a:hover {
    background: linear-gradient(135deg, #03001e 0%, #98c9c2 100%);
    color: #fff;
    text-decoration: none;
}

.menu i {
    color: #fff;
    font-size: 1rem;
}
.menu-area {
    background: #03001e;
}

.menu button {
    font-size: 0.75rem;
    border: none;
    display: block;
    color: #fff;
    font-weight: 300;
    padding: 5px;
    font-size: 0.75rem;
    background: #03001e;
}

.b-sidebar-body {
    background: #03001e;
    padding: 10px;
}

.b-sidebar-body i {
    color: #fff;
    font-size: 1rem;
}

.b-sidebar-body a {
    display: block;
    text-decoration: none;
    color: #fff;
    font-weight: 300;
    padding: 5px;
    font-size: 0.75rem;
}

.b-sidebar-body a:hover {
    background: linear-gradient(135deg, #03001e 0%, #98c9c2 100%);
    color: #fff;
    text-decoration: none;
}

.b-sidebar title {
     font-size: 0.75rem;
}


</style>
