<template>
        <div class="companyvalidatedate">
        <PageTitle
            icon="fa fa-clone"
            main="Documento"
            sub="Empresa"
        />
          <b-card bg-variant="light" text-variant="dark" sub-title="Documento(s) - Data de Validade">
            <b-row>
                <b-col md="6" sm="12">
                     <b-button
                            title="Solicitar Autorização"
                            size="sm"
                            variant="info"
                            @click="changeFile()"
                            :disabled="request_accept"
                            class=" mr-2"
                        >

                            <div  class="d-flex justify-content-center ">
                            <b-spinner v-show="request_accept" label="Enviando..."></b-spinner>
                        </div>
                        <div v-show="!request_accept">
                                <i  v-show="!request_accept" class="fa fa-exchange"></i>
                                Solicitar Autorização
                            </div>
                    </b-button>
                </b-col>
            </b-row>
            <br>
            <b-row>
                   <b-col md="12" sm="12">
                             <b-table
                                        class="table-responsive"
                                        hover
                                        head-variant="ligth"
                                        :items="users_files"
                                        :fields="fieldsFile"
                                    >
                                    <template v-slot:cell(actions)="data">
                                            <b-button
                                                title="Visualizar"
                                                size="sm"
                                                variant="outline-dark"
                                                @click="
                                                    ;[
                                                        loadFile(data.item),
                                                        openModalPDF()
                                                    ]
                                                "
                                                class="mr-2 mt-2"
                                            >
                                                <i class="fa fa-file-pdf-o"></i>
                                                Visualizar
                                            </b-button>
                                            
                                            
                                    </template>
                                     <template v-slot:cell(actions1)="data">
                                        <b-button
                                                title="Editar Arquivo"
                                                size="sm"
                                                variant="outline-dark"
                                                @click="[loadFile(data.item),openModalFile()]"
                                                class="mr-2 mt-2"
                                            >
                                                <i class="fa fa-pencil"></i>
                                                Editar
                                            </b-button>
                                    </template>
                                  
                             </b-table>
                          </b-col>
            </b-row>
          </b-card>
          <b-modal
                    id="modalPDF"
                    ref="modal"
                    title-tag="h6"
                    centered
                    size="lg"
                    ok-only
                    title="Visualização do Documento"
                >
                    <div>
                        <pdf :src="users_file.url_file"></pdf>
                    </div>
        </b-modal>
         <b-modal
             v-model="modalFileShow"
            id="modalFile"
            title-tag="h6"
            centered
            size="lg"
            title="Arquivo"
        >
        <b-row >
                  <b-col md="3" sm="12">
                    <b-form-group
                        label="Data de Validade:"
                        label-for="users_file-validade_date_changed_validate"
                        >
                        <b-form-input
                            id="users_file-validade_date_changed_validate"
                            type="date"
                            v-model="users_file.validade_date_changed_validate"
                            size="sm"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row >
                <b-col md="5" sm="12">
                    <b-form-file
                        accept=".pdf"
                        v-model="file"
                        class="mt-4 mb-4"
                        plain
                        @input="saveImagem"
                        :disabled="file_doc_show"
                        size="sm"
                    ></b-form-file>
                </b-col>
            </b-row>
              <b-row v-show="!users_file.has_date">
                <b-col md="5" sm="12">
                    <b-form-file
                        accept=".pdf"
                        v-model="file"
                        class="mt-4 mb-4"
                        plain
                        @input="saveImagem"
                        :disabled="file_doc_show"
                        size="sm"
                    ></b-form-file>
                </b-col>
              </b-row>
            <template #modal-footer>
                    <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        v-show="!file_upload_show"
                        class="float-right"
                        @click="modalFileShow=false"
                    >
                        Fechar
                    </b-button>
                    <b-button variant="primary" disabled  v-show="file_upload_show">
                        <b-spinner small type="grow"></b-spinner>
                        Carregando...
                    </b-button>
                    </div>
                </template>
        </b-modal>
        </div>
</template>
<script>

import { baseApiUrl,showError } from '@/global'
import axios from 'axios'
import PageTitle from '../template/PageTitle'
import pdf from 'vue-pdf'

var moment = require('moment-timezone')

export default {
    name: 'CompanyValidateDate',
   components: { PageTitle,pdf},
    data: function(){
        return{
            users_files: [],
            users_file: {},
            file_upload_show: false,
            request_accept:false,
            file: null,

            fieldsFile: [
                {
                    key: 'name_type_document_unique',
                    label: 'Documento',
                     variant: 'light',
                    sortable: true
                },
                {
                    key: 'justify_comments_validate',
                    label: 'Justificativa',
                     variant: 'light',
                    sortable: false
                },  
                 {
                    key: 'validade_date_validate',
                    label: 'Validade',
                     variant: 'light',
                    sortable: false
                },  
                {
                    key: 'validade_date_changed_validate',
                    label: 'Validade Alterada',
                      formatter: value =>
                                value ? moment.utc(value).format('DD-MM-YYYY') : '',
                     variant: 'light',
                    sortable: false
                },        
                {
                    key: 'changed_validate',
                    label: 'Alterado',
                    sortable: false,
                    formatter: value => (value ? 'Sim' : 'Não')
                },
                { key: 'actions', label: '', variant: 'light' },
                { key: 'actions1', label: '', variant: 'light' },
               
                ]
        }
    },
    computed: {
        compan() {
            return this.$store.state.compan
        },
        user() {
            return this.$store.state.user
        }
    },
    methods: {
         showMsgBoxSendFile(item) {
                this.boxCancel = ''
                this.$bvModal
                .msgBoxConfirm('Deseja enviar o arquivo ?', {
                    title: 'Por Favor Confirme',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'SIM',
                    cancelTitle: 'NÃO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async value => {
                    this.boxCancel = value
                    if (this.boxCancel === true) {
                        this.file_upload_table = true    
                        await this.checkFileFtp(item)
        
                    }
                })
            },
             openModalPDF() {
                this.$bvModal.show('modalPDF')
            },
            openModalFile() {
                this.file_doc_show = false
                this.$bvModal.show('modalFile')
            },
            closeModalFile(){
                this.$bvModal.hide('modalFile')
            },
            loadFile(item) {
                this.users_file = { ...item,validade_date:null }
            },
            async  loadUsesFileRejects() {
            const url = `${baseApiUrl}/usersfilerejectuservalidatedates/${this.user.id}`
            await  axios(url).then(res => {
                    this.users_files = res.data
                })
            },
            async saveImagem() {

            this.file_upload_show = true
            let formData = new FormData()
            formData.append('file', this.file)
            formData.append('user_id_unique', this.user.id)
            formData.append('type_document_unique_id', this.users_file.type_document_unique_id_validate)

            await axios
                .post(`${baseApiUrl}/storagefiles`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then( async () => {
                    this.$toasted.global.defaultSuccess()
                     await  this.getLastStorageFile()
                    this.type_doc_show =true
                })
                .finally(async ()=>{
                    if(this.users_file.storage_file_id_validate > 0){
                      await  this.saveFile()
                    }else{
                          this.$toasted.global.defaultWarning({
                                msg: "Nâo foi possivel gravar o arquivo!Tente novamente....",
                                });
                    }
                })
                .catch(showError)
            },
            async getLastStorageFile() {
            const url = `${baseApiUrl}/storagefilesuniquebylast/?name_file=${this.file.name}&user_id_unique=${this.user.id}&type_document_unique_id=${this.users_file.type_document_unique_id_validate}`
            await axios(url).then(
                res =>
                    (this.users_file = {
                        ...this.users_file,
                        storage_file_id_validate: res.data.id,
                        name_file_validate: res.data.name_file,
                        path_file_validate: res.data.path_file,
                        changed_validate: 1,
                        id: this.users_file.id,
                        type_document_unique_id_validate: this.users_file
                            .type_document_unique_id_validate
                    })
            )
        },
        async saveFile() {
            this.users_file.user_id_file = this.user.id
            const method = this.users_file.id ? 'put' : 'post'
            const id = this.users_file.id
                ? `/${this.users_file.id}`
                : ''
            await axios[method](
                `${baseApiUrl}/usersfilesvalidatedates${id}`,
                this.users_file
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()  
                })
                .finally(async ()=>{
                   await this.checkFileFtp(this.users_file)
                     this.closeModalFile()
                })
                .catch(showError)
        },
         async checkUserFileFtp(item) {

            this.users_file = { ...item }
            
            if(item.storage_file_id_validate > 0){
                  await axios['put'](
                `${baseApiUrl}/usersfilesftp/${this.users_file.id}`,
                this.users_file
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                     this.loadUsesFileRejects()
                })
                 .finally( () => {
                     this.file_upload_table = false
                })
                .catch(showError)
            }else{
                this.$toasted.global.defaultWarning({
                    msg: "Não existe arquivo anexo para envio!Favor verificar..",
                    });
            }
          
        },
          async changeFile() {           

             this.request_accept = true

                await axios['post'](
               `${baseApiUrl}/usersfileschangevalidatedate/${this.user.id}`
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()    
                     this.request_accept = false
                      this.loadUsesFileRejects()
                })
                .catch(showError)
                this.request_accept = false
        },
          async checkFileFtp(item) {

            this.users_file = {...item}

            if(this.users_file.storage_file_id_validate > 0){
                  await axios['put'](
                `${baseApiUrl}/usersfilesftpvalidatedate/${this.users_file.id}`,
                this.users_file
            )
                .then(async() => {
                    this.$toasted.global.defaultSuccess()
                     await  this.loadUsesFileRejects()
                       this.file_upload_show = false
                })
                .finally( () => {
                     this.file_upload_table = false   
                     this.file_upload_show = false
                   
                })
                .catch(showError)
            }else{
                this.$toasted.global.defaultWarning({
                    msg: "Não existe arquivo anexo para envio!Favor verificar..",
                    });
            }                   
        },
    },
    async mounted() {
         await  this.loadUsesFileRejects()
     }
}
</script>
<style>
    .companyvalidatedate {
        font-size: 0.75rem;
    }

    .companyvalidatedate button {
    font-size: 0.75rem;
    }

    #modalFile {
        font-size: 0.75rem;
    }

    #modalFile input {
        font-size: 0.75rem;
    }

    #modalFile button{
        font-size: 0.75rem;
    }

</style>