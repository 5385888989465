<template>
    <div class="accordion">
        <b-row>
            <b-button variant="primary" disabled  v-show="employee_show">
                        <b-spinner small type="grow"></b-spinner>
                        Carregando status funcionário...
             </b-button>
        </b-row>
        <b-row>
            <b-button variant="primary" disabled  v-show="employee_file_show">
                        <b-spinner small type="grow"></b-spinner>
                        Carregando status documento(s) do funcionário...
             </b-button>
        </b-row>
        <b-row>
            <b-button variant="primary" disabled  v-show="request_show">
                        <b-spinner small type="grow"></b-spinner>
                        Carregando status solicitação da empresa...
             </b-button>
        </b-row>
        <b-row>
            <b-button variant="primary" disabled  v-show="request_file_show">
                        <b-spinner small type="grow"></b-spinner>
                        Carregando status documento(s) da empresa...
             </b-button>
        </b-row>
        <b-row>
            <b-button variant="primary" disabled  v-show="user_complement_show">
                        <b-spinner small type="grow"></b-spinner>
                        Carregando dados da empresa...
             </b-button>
        </b-row>
         <b-row>
        <b-button variant="primary" disabled  v-show="monthly_show">
                        <b-spinner small type="grow"></b-spinner>
                        Carregando status documento(s) mensal...
             </b-button>
        </b-row>
        

    </div>
</template>

<script>
import { baseApiUrl } from '@/global'
import axios from 'axios'

export default {
    name: 'LoadingProcess',
    computed: {
         compan() {
            return this.$store.state.compan
        },
        user() {
            return this.$store.state.user
        }
    },
    data: function() {
        return {
            user_files: [],
            user_files_validate_dates: [],
            user_request: [],
            user_complement: [],
            files:[],
            monthly_files:[],
            execute_monthly_not_send_not_exist_path:[],
            execute_monthly_not_send_exist_path:[],
            execute_monthly_file_not_exist_preview:[],
            company_active:[],
            employee_show: false,
            employee_file_show: false,
            request_show:false,
            request_file_show:false,
            user_complement_show: false,
            monthly_show: false,
            
        }
    },
    methods: {

        async loadUserFiles() {
              if(this.user.id > 0){
                   this.request_file_show = true
                     const url = `${baseApiUrl}/usersfilefluig/${this.user.id}`
                    await axios.get(url).then(res => {
                        this.user_files = res.data
                    })
                        .finally(async () => {
                      this.request_file_show = false
                       await this.loadUserComplement()
                    })
              }
        }, 
         async loadUserFilesValidadeDate() {
              if(this.user.id > 0){
                     const url = `${baseApiUrl}/usersfilevalidatedatefluig/${this.user.id}`
                    await axios.get(url).then(res => {
                        this.user_files_validate_dates = res.data
                    })
              }
        }, 
         async loadUserRequest() {
            if(this.user.id > 0){
                    this.request_show = true
                    const url = `${baseApiUrl}/executesearchrequest/${this.user.id}`
                    await axios.get(url).then(res => {
                        this.user_request = res.data
                    })
                    .finally(async  () => {
                      this.request_show = false
                        await this.loadUserFiles()
                    })
            }
        },
        async loadUserComplement() {
            if(this.user.id > 0){
                      this.user_complement_show = true
                    const url = `${baseApiUrl}/executesearchcomplement/${this.user.id}`
                    await axios.get(url).then(res => {
                        this.user_complement = res.data
                    })
                      .finally( () => {
                      this.user_complement_show = false
                      //  await this.loadUserFiles()
                    })
            }

        }, 
        async loadCompanyFiles() {

              this.employee_file_show = true

            const url = `${baseApiUrl}/companyemployeefilefluig/${this.user.id}`
            await axios.get(url).then(res => {
                this.files = res.data
            })
                .finally( () => {
                      this.employee_file_show = false
                      //  await this.loadUserFiles()
                    })
        }, 
        async loadMonthlyFiles() {

            this.monthly_show = true

            const url = `${baseApiUrl}/companydocumentmonthlyfilefluig/${this.user.id}`
            await axios.get(url).then(res => {
                this.monthly_files = res.data
            })
                .finally( () => {
                      this.monthly_show = false
                    })
        },     
        async executeMonthlyNotSendNotExistPath() {

            const url = `${baseApiUrl}/executemonthlynotsendnotexistpath`
            await axios.get(url).then(res => {
                this.execute_monthly_not_send_not_exist_path = res.data
            })

        },    
        async executeMonthlyNotSendExistPath() {

            const url = `${baseApiUrl}/executemonthlynotSendexistpath`
            await axios.get(url).then(res => {
                this.execute_monthly_not_send_exist_path = res.data
            })

        },  
        async executeMonthlyFileNotExistPreview() {

            const url = `${baseApiUrl}/executemonthlyfilenotexistpreview`
            await axios.get(url).then(res => {
                this.execute_monthly_not_send_exist_path = res.data
            })

        },
        async executeCompanyActive() {

            const url = `${baseApiUrl}/executecompanyactive`
            await axios.get(url).then(res => {
                this.company_active = res.data
            })

        },
    
    },
   async mounted() {

       // await this.loadCompanyFiles()
        await this.loadMonthlyFiles()
        await  this.loadUserRequest()
        await this.loadUserFilesValidadeDate()
         await this.executeCompanyActive()
        await this.executeMonthlyNotSendNotExistPath()
        await this.executeMonthlyNotSendExistPath()
        await this.executeMonthlyFileNotExistPreview()
       
    }
}
</script>

<style>
.accordion {
    width: 100%;
    align-items: flex-end;
    font-size: 0.75rem;
}

.accordion button {
    font-size: 0.9rem;
}

.accordion select {
    font-size: 0.75rem;
}

.accordion ul {
    font-size: 0.75rem;
    list-style-type: none;
}

</style>
