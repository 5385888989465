import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/components/home/Home'
import AdminPages from '@/components/admin/AdminPages'
import Auth from '@/components/auth/Auth'
import EmployeeSearch from '@/components/pages/EmployeeSearch'
import RejectFileEmployee from '@/components/pages/RejectFileEmployee'
import RejectFileMonth from '@/components/pages/RejectFileMonth'
import RejectFileCompany from '@/components/pages/RejectFileCompany'
import RejectFileCompanyEmployee from '@/components/pages/RejectFileCompanyEmployee'
import CompanyRegister from '@/components/pages/CompanyRegister'
import CompanyDocumentMonthly from '@/components/pages/CompanyDocumentMonthly'
import RegisterEmployeeDoc from '@/components/pages/RegisterEmployeeDoc'
import CompanyValidateDate from '@/components/pages/CompanyValidateDate'

import {
    userKey
} from '@/global'

Vue.use(VueRouter)

const routes = [{
        name: 'home',
        path: '/',
        component: Home
    },
    {
        name: 'adminPages',
        path: '/admin',
        component: AdminPages,
        meta: {
            requiresAdmin: true
        }
    },
    {
        name: 'auth',
        path: '/auth',
        component: Auth
    },  
    {
        name: 'CompanyRegister',
        path: '/company',
        component: CompanyRegister
    }, 
    {
        name: 'EmployeeSearch',
        path: '/employee',
        component: EmployeeSearch
    }, 
    {
        name: 'RejectFileEmployee',
        path: '/rejectfileemployee',
        component: RejectFileEmployee
    }, 
    {
        name: 'RejectFileMonth',
        path: '/rejectfilemonth',
        component: RejectFileMonth
    }, 
    {
        name: 'RejectFileCompany',
        path: '/rejectfilecompany',
        component: RejectFileCompany
    }, 
    {
        name: 'RejectFileCompanyEmployee',
        path: '/rejectfilecompanyemployee',
        component: RejectFileCompanyEmployee
    }, 
    {
        name: 'CompanyDocumentMonthly',
        path: '/companydocumentmonthly',
        component: CompanyDocumentMonthly
    }, 
    {
        name: 'RegisterEmployeeDoc',
        path: '/registeremployeedoc',
        component: RegisterEmployeeDoc
    }, 
    {
        name: 'CompanyValidateDate',
        path: '/companyvalidatedates',
        component: CompanyValidateDate
    }, 

]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
        const json = localStorage.getItem(userKey)
        if (to.matched.some(record => record.meta.requiresAdmin)) {
            const user = JSON.parse(json)
            user && user.admin || user.super ? next() : next({
                path: '/'
            })
        } else {
            next()
        }
    }

)


export default router